import React from 'react';
import styled, { css } from 'styled-components';

import AppIcons from '@components/AppIcons';

import mq, { mqdown } from '@mq';
import { isSmallDevice } from '@utils';
import { center } from '@mixins';

const TextSliderSection = ({
  title,
  items,
  imageRight = false,
  illustration: Illustration,
  context,
}) => (
  <Container>
    {!isSmallDevice && (
      <IllustrationContainer imageRight={imageRight}>
        <StickyContainer>
          <Illustration />
        </StickyContainer>
      </IllustrationContainer>
    )}
    <Items imageRight={imageRight}>
      {items.map((item, index) => (
        <Item key={item.id} index={index} {...item} title={title} context={context} />
      ))}
    </Items>
  </Container>
);

const Item = ({ title, index, text, context }) => (
  <ItemContainer>
    {index === 0 && <Title>{title}</Title>}
    <Index>
      <Circle>{index + 1}</Circle>
    </Index>
    <Text>{text}</Text>
    {index === 0 && <TextSliderAppIcons context={context} />}
  </ItemContainer>
);
const Container = styled.section`
  padding-top: 0;
  row-gap: var(--space-l);
`;

const IllustrationContainer = styled.div`
  ${getIllustrationColumns};
`;

const StickyContainer = styled.div`
  ${center};

  ${mq.medium} {
    position: sticky;
    top: 25vh;
  }
`;

const Items = styled.ul`
  ${getTextColumns};
`;

const ItemContainer = styled.li`
  display: grid;

  ${mq.small} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: var(--grid-gap);
  }

  ${mq.medium} {
    grid-template-columns: repeat(7, 1fr);
  }

  ${mq.large} {
    grid-template-columns: repeat(6, 1fr);
  }

  > div:last-child {
    margin-bottom: var(--space-m);

    ${mq.small} {
      margin-bottom: var(--space-xl);
    }
  }

  ${mqdown.small} {
    &:last-child {
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Title = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  grid-row: 1;
  margin-bottom: var(--space-s);

  ${mq.small} {
    grid-column: 2 / -1;
    margin-bottom: var(--space-xl);
  }

  ${mq.large} {
    grid-column: 1 / -1;
  }
`;

const Index = styled.div`
  ${center};
  position: sticky;
  top: var(--navigation-height);

  ${mqdown.small} {
    position: static;
    align-items: center;
    margin-bottom: var(--space-xs);
  }

  ${mq.small} {
    margin-top: 0.7rem;
    grid-row: 2;
    align-self: start;
  }
`;

const Circle = styled.div`
  ${center};
  width: 3.125rem;
  height: 3.125rem;
  background-color: var(--color-secondary);
  border-radius: 50%;
  font: var(--font-m);
  background-color: ${props => props.active && 'var(--color-primary)'};
  transition: background var(--motion-double);

  ${mq.small} {
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const Text = styled.div`
  text-align: center;
  font: var(--font-m);

  ${mq.small} {
    grid-column: 2 / -1;
    grid-row: 2;
    letter-spacing: var(--letter-spacing-l);
    font: var(--font-l);
  }

  ${mq.large} {
    grid-column: 2 / -2;
  }
`;

const TextSliderAppIcons = styled(AppIcons)`
  grid-column: 1 / -1;
  margin-top: var(--space-xs);

  ${mq.small} {
    grid-column: 2 / -1;
    grid-row: 3;
    margin-top: var(--space-s);
  }

  ${mq.large} {
    grid-column: 2 / -2;
  }
`;

function getIllustrationColumns({ imageRight }) {
  if (!imageRight) {
    return css`
      grid-column: 1 / -1;

      ${mq.medium} {
        grid-column: 1 / 6;
        grid-row: 1;
      }

      ${mq.large} {
        grid-column: 2 / 6;
      }

      ${mq.huge} {
        grid-column: 5 / 10;
      }
    `;
  }

  return css`
    grid-column: 1 / -1;

    ${mq.medium} {
      grid-column: 8 / -1;
      grid-row: 1;
    }

    ${mq.large} {
      grid-column: 8 / -2;
    }

    ${mq.huge} {
      grid-column: 11 / -5;
    }
  `;
}

function getTextColumns({ imageRight }) {
  if (!imageRight) {
    return css`
      grid-column: 1 / -1;

      ${mq.medium} {
        grid-column: 6 / -1;
        grid-row: 1;
      }

      ${mq.large} {
        grid-column: 7 / -1;
      }

      ${mq.huge} {
        grid-column: 11 / -4;
      }
    `;
  }

  return css`
    grid-column: 1 / -1;

    ${mq.medium} {
      grid-column: 1 / 7;
      grid-row: 1;
    }

    ${mq.large} {
      grid-column: 1 / 7;
    }

    ${mq.huge} {
      grid-column: 4 / 10;
    }
  `;
}

export default TextSliderSection;
