import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import RichText from '@core/RichText';
import Seo from '@core/Seo';

import illustrations from '@common/illustrations';

import Sections from '@components/Sections';
import TextSliderSection from '@components/TextSliderSection';

const DeskIllustration = illustrations.desks;

const NeedSpacePage = ({
  data: {
    contentfulNeedSpacePage: { title, subtitle, sections, textSliderTitle, textSlider },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Whether you need a desk for a day, a meeting room for an afternoon, your very own private office, or just to mingle with some interesting new people, we will help you find a workspace that suits your needs at a location within walking distance."
      pageImage="/seo/needspace.jpg"
    />
    <main>
      <MobileIllustrationContainer>
        <DeskIllustration mobile />
      </MobileIllustrationContainer>
      <section>
        <h1>{title}</h1>
        <Subtitle text={subtitle} />
      </section>
      <TextSliderSection
        title={textSliderTitle}
        items={textSlider}
        illustration={DeskIllustration}
        context="needspace"
      />
      <Sections sections={sections} />
    </main>
  </>
);

// TODO
// since it's repeating (like the main title), perhaps move out
const Subtitle = styled(RichText)`
  grid-column: 1 / -1;
  text-align: center;

  ${mqdown.small} {
    margin-top: var(--space-xxs);
  }

  ${mq.small} {
    font: var(--font-subtitle);
  }

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const MobileIllustrationContainer = styled.section`
  &:first-child {
    padding-top: calc(var(--space-s) + var(--space-xxs));
    padding-bottom: 0;
    margin-bottom: calc(var(--space-xxs) * -4);
  }

  > div {
    grid-column: 1 / -1;
  }

  ${mq.small} {
    display: none;
  }
`;

export const needSpacePageQuery = graphql`
  query NeedSpacePageQuery($locale: String) {
    contentfulNeedSpacePage(node_locale: { eq: $locale }) {
      node_locale
      title
      subtitle {
        raw
      }
      textSliderTitle
      textSlider {
        id
        text
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(NeedSpacePage);
